<!--
PortfolioMixin:
Loads a specific portfolio for pages with router argument "portfolio_id"

Provides: portfolio, portfolioLoading, portfolioReady
Required: AllPortfoliosMixin
-->
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    // Properties set by router:
    portfolio_id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      __portfolioById: 'portfolio/getById',
      __sustainabilityIndicators: 'portfolio/getSustainabilityIndicators',
      __reidaEnergyCarriers: 'portfolio/getReidaEnergyCarriers',
    }),

    portfolio() {
      return this.__portfolioById(this.portfolio_id)
    },

    reidaEnergyCarriers() {
      return this.__reidaEnergyCarriers()
    },

    sustainabilityIndicators() {
      return this.__sustainabilityIndicators()
    },

    portfolioLoading() {
      return this.portfolio !== undefined && this.portfolio.__details_loading
    },

    portfolioReady() {
      return this.portfolio !== undefined && this.portfolio.__details_loaded
    },
  },

  watch: {
    allPortfolios() {
      this.__loadPortfolioDetailsById(this.portfolio_id)
      this.__loadSustainabilityIndicatorsByPortfolioId(this.portfolio_id)
      this.__loadReidaEnergyCarriers()
    },

    portfolio_id() {
      this.__loadPortfolioDetailsById(this.portfolio_id)
      this.__loadSustainabilityIndicatorsByPortfolioId(this.portfolio_id)
      this.__loadReidaEnergyCarriers()
    },
  },

  created() {
    this.__loadPortfolioDetailsById(this.portfolio_id)
    this.__loadSustainabilityIndicatorsByPortfolioId(this.portfolio_id)
    this.__loadReidaEnergyCarriers()
  },

  methods: {
    ...mapActions({
      __loadPortfolioDetailsById: 'portfolio/loadDetailsById',
      __loadSustainabilityIndicatorsByPortfolioId: 'portfolio/loadSustainabilityIndicatorsByPortfolioId',
      __loadReidaEnergyCarriers: 'portfolio/loadReidaEnergyCarriers',
    }),
  },
}
</script>
