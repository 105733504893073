<i18n>
{
  "de": {
    "portfolio": "Portfolio"
  }
}
</i18n>

<template>
  <div class="c-page-title">
    <!-- Breacrumbs -->
    <div class="breadcrumbs">
      <router-link :to="`/portfolios/${portfolio.id}`">{{ `${$t('portfolio')}: ${portfolio.name}` }}</router-link>
      <div v-for="item in breadcrumbs" :key="item.path">
        <span class="breadcrumbs-separator">/</span>
        <router-link :to="item.path">
          {{ `${item.label}` }}
        </router-link>
      </div>
      <slot>
        <!-- Other routes -->
      </slot>
    </div>
    <!-- Title -->
    <div class="title-row">
      <div class="title-wrapper">
        <slot name="icon"></slot>
        <h1 v-html="title" />
      </div>
      <slot name="actions"></slot>
    </div>
    <slot name="details"></slot>
    <hr v-if="showSeparator" />
  </div>
</template>

<script>
export default {
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    showSeparator: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.c-page-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;

  & .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    & h1 {
      font-size: 32px;
      line-height: 40px;
      margin: 0;
    }

    & .title-wrapper {
      display: flex;
      align-items: center;
      gap: var(--spacing-xs);
    }
  }

  & .breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;

    & * {
      margin-right: 8px;
    }

    & a {
      text-decoration: none;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0;
      font-weight: 500;
    }

    & div {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0;
      font-weight: 500;
      color: #999;
    }

    & .breadcrumbs-separator {
      color: #999;
      margin-right: 5px;
    }
  }

  & hr {
    margin: 16px 0 0;
    width: 100%;
    height: 1px;
    background-color: #eee;
    border: none;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
}
</style>
