<template>
  <section :class="classes">
    <h3 v-if="title">{{ title }}</h3>
    <ul>
      <template v-if="hasHeader">
        <li v-if="!stickyHeader" class="header">
          <slot name="header"> </slot>
        </li>
        <!-- WIP: ability to have sticky headers? -->
        <!-- Works for y overflow, but not for x -->
        <!-- <li v-if="stickyHeader" v-sticky="true" sticky-offset="{ top: 50 }" sticky-z-index="8" class="header">
          <slot name="header"> </slot>
        </li> -->
      </template>

      <slot></slot>

      <li v-if="hasFooter" class="footer">
        <slot name="footer"></slot>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    hasHeader: {
      type: Boolean,
      default: false,
    },
    hasFooter: {
      type: Boolean,
      default: false,
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        'detail-list': true,
        wide: this.wide,
      }
    },
  },
}
</script>

<style lang="scss">
/* @media screen and (min-width: 52.9rem) {
  .detail-list.wide {
    grid-column: 1 / 3;
  }
} */

.detail-list {
  h3 {
    margin-top: var(--spacing-s);
  }
}

.detail-list > ul > li {
  position: relative;
  display: flex;
  flex-direction: row;
  /* grid-template-columns: repeat(auto-fit, minmax(40px, 1fr)); */
  /* column-gap: var(--box-padding-h); */
  align-items: center;
  justify-content: space-between;
  gap: var(--box-padding-h);

  & > * {
    flex: 1 40px;
  }

  & > p {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .sticky-column {
    background-color: white;
    padding: 5px 10px;
    position: sticky;
    z-index: 3;
    left: 0;
    border-right: 1px solid #ddd;
  }

  &:hover {
    background-color: #f8f8f8;
  }

  &:hover .sticky-column {
    background-color: #f8f8f8;
  }

  &:hover.header,
  &:hover.header .sticky-column {
    background-color: white;
  }
}

.detail-list > ul > li.header {
  font-size: var(--font-xs);
  font-weight: 600;
  color: var(--secondary-highlight-color);
  background-color: white;
  border-radius: var(--spacing-xs) var(--spacing-xs) 0px 0px;

  position: sticky;
  top: 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: -1px;
  z-index: 4;

  & * {
    line-height: 20px;
    display: flex;
    flex-direction: row;

    & .c-info-box {
      display: flex !important;
    }
  }
}

.detail-list > ul > li.footer {
  font-weight: 400;
  color: var(--secondary-highlight-color);
  border-radius: 0px 0px var(--spacing-xs) var(--spacing-xs);
}

.detail-list > ul {
  position: relative;
  border: var(--box-border);
  border-radius: var(--box-radius);
}

.detail-list > ul > li:not(.detail-list-item) {
  padding: var(--box-padding);
}

.detail-list > ul > li ~ li {
  border-top: var(--box-border);
}

.detail-list > ul > li:first-child {
  border-radius: var(--spacing-xs) var(--spacing-xs) 0px 0px;
}

.detail-list > ul > li:last-child {
  border-radius: 0px 0px var(--spacing-xs) var(--spacing-xs);
}

.detail-list > ul > li:only-child {
  border-radius: var(--spacing-xs);
}
</style>
