<!--
PortfolioBuildingsForListMixin:
Loads buildings for a specific portfolio

Provides: buildings, buildingsForListLoading, buildingsForListLoaded
Required: PortfolioMixin
-->
<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('portfolioBuildingsForList', {
      buildingsForList: 'buildingsForList',
      buildingsForListLoading: 'loading',
      buildingsForListLoaded: 'loaded',
    }),
  },

  watch: {
    portfolio_id() {
      this.__load({ portfolioId: this.portfolio_id })
    },
  },

  created() {
    this.__load({ portfolioId: this.portfolio_id })
  },

  methods: {
    ...mapActions({
      __load: 'portfolioBuildingsForList/load',
    }),
  },
}
</script>
